import {
    clearOneTimeTokenAccessToken,
    getClientUserOneTimeToken,
    getClientUserToken,
} from '../apiClient/shareLinkSessionStorage';

const checkOneTimeToken = (expireTime: number, showRenewAccessMessage: () => Promise<unknown>) => {
    setTimeout(async () => {
        if (await showRenewAccessMessage()) {
            window.location.href = `${
                window.location.origin
            }/clientsharelink/${getClientUserOneTimeToken()}`;
        }
    }, expireTime * 1000);
};

interface LoadClientSessionResult {
    accessToken: string;
    signOut: () => void;
}
export function loadClientSession(
    showRenewAccessMessage: () => Promise<unknown>
): LoadClientSessionResult | undefined {
    const clientToken = getClientUserToken();
    if (!clientToken) {
        return;
    }

    try {
        checkOneTimeToken(clientToken.expires_in as number, showRenewAccessMessage);
    } catch (e) {
        clearOneTimeTokenAccessToken();
        return;
    }

    if (new Date(clientToken.expiresAt as string) < new Date()) {
        clearOneTimeTokenAccessToken();
        return;
    }

    return {
        accessToken: clientToken.access_token as string,
        signOut() {
            clearOneTimeTokenAccessToken();
        },
    };
}
